import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import SectionBox from "../components/shared/SectionBox";
import SpeakWithAgent from "../components/speakWithAgent";
import CoversSection from "../components/shared/CoversSection";
import ProsAndCons from "../components/shared/ProsAndCons";
import ProductHeroSection from "../components/shared/ProductHeroSection";
import WhyProduct from "../components/shared/WhyProduct";
import RelatedProducts from "../components/shared/RelatedProducts";
import Resources from "../components/shared/Resources";
import FaqSection from "../components/shared/FaqSection";
// import Seo from "gatsby-plugin-wpgraphql-seo";
// import { customSchema } from "../components/shared/customSchema";
import { Wysiwyg } from "../components/shared/Wysiwyg";
import ProductHeroSectionCtaBtn from "../components/shared/ProductHeroSectionCtaBtn";
import { useWebsiteData } from "../context/AppContext";
import Banner from "../components/shared/Banner";
import BodyImage from "../components/shared/BodyImage";
import SeoDataFetcher from "../components/SeoDataFetcher.js";

const ProductPage = ({ data, location }) => {
  const { state } = useWebsiteData();
  const { tempAgent } = state;

  const {
    productData,
    faqProducts: { nodes: faqProductsData },
  } = data;
  const { products: product, banner } = productData;

  const pageUrl = `https://wordpress.myhealthinsurance.com${location.pathname}`;

  return (
    <Layout location={location} productFootnotes>
      {/*<Seo*/}
      {/*  post={productData}*/}
      {/*  postSchema={customSchema(productData.seo.schema.raw)}*/}
      {/*/>*/}
      <SeoDataFetcher url={pageUrl} />
      {productData?.hideFormOnProductPage?.showCtaForm ? (
        <ProductHeroSectionCtaBtn
          image={productData?.featuredImage}
          subtitle={product.heroContent}
          title={productData?.title}
          mobileImage={productData?.mobileFeaturedImage?.mobileFeaturedImage}
          location={location}
        />
      ) : (
        <ProductHeroSection
          image={productData?.featuredImage}
          subtitle={product.heroContent}
          title={productData?.title}
          mobileImage={productData?.mobileFeaturedImage?.mobileFeaturedImage}
          location={location}
        />
      )}

        {/*add missing code for why this prodcut on default product tempalte*/}
        {/* Render the WhyProduct section if the slug matches and data exists */}
        { productData?.slug === "cancer-insurance"
            && !productData.cancerInsuranceProducts.hideWhyThisProductSection
            && productData.cancerInsuranceProducts && (
                <div className="why-product-section">
                    <div className="container is-widescreen">
                        {productData.cancerInsuranceProducts.whyThisProductSectionTitle && (
                            <h3>{productData.cancerInsuranceProducts.whyThisProductSectionTitle}</h3>
                        )}
                        <div className="why-product-columns">
                            <div className="why-product-column">
                                <div className="why-product-icon">
                                    <BodyImage image={productData.cancerInsuranceProducts.whyThisProductBoxOneIcon} />
                                </div>
                                <span className="why-product-title">
                                  {productData.cancerInsuranceProducts.whyThisProductBoxOneTitle}
                                </span>
                                <div
                                    className="why-product-content"
                                    dangerouslySetInnerHTML={{
                                        __html: productData.cancerInsuranceProducts.whyThisProductBoxOneContent,
                                    }}
                                />
                            </div>
                            <div className="why-product-column">
                                <div className="why-product-icon">
                                    <BodyImage image={productData.cancerInsuranceProducts.whyThisProductBoxTwoIcon} />
                                </div>
                                <span className="why-product-title">
                                  {productData.cancerInsuranceProducts.whyThisProductBoxTwoTitle}
                                </span>
                                <div
                                    className="why-product-content"
                                    dangerouslySetInnerHTML={{
                                        __html: productData.cancerInsuranceProducts.whyThisProductBoxTwoContent,
                                    }}
                                />
                            </div>
                            <div className="why-product-column">
                                <div className="why-product-icon">
                                    <BodyImage image={productData.cancerInsuranceProducts.whyThisProductBoxThreeIcon} />
                                </div>
                                <span className="why-product-title">
                                  {productData.cancerInsuranceProducts.whyThisProductBoxThreeTitle}
                                </span>
                                <div
                                    className="why-product-content"
                                    dangerouslySetInnerHTML={{
                                        __html: productData.cancerInsuranceProducts.whyThisProductBoxThreeContent,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*end of why product section*/}

      <SectionBox image={product.whatProductCoversImage}>
        <h3>{product.productTitle}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: product.productDescription,
          }}
        />
      </SectionBox>
      <CoversSection
        coversTitle={product.coversTitle}
        covers={product.covers}
        doesNotCoversTitle={product.doesNotCoversTitle}
        doesNotCover={product.doesNotCover}
      />
      {banner?.bannerUrl && (
        <div className="product-banner-section">
          <Banner banner={banner} />
        </div>
      )}
      <ProsAndCons
        pros={product.pros}
        cons={product.cons}
        prosImage={product.prosImage}
        consImage={product.consImage}
        prosAndConsTitle={product.prosAndConsTitle}
      />
      <FaqSection
        // title={`Find Answers to ${productData.title} Questions`}
        title={`Frequently Asked Questions Regarding ${productData.title}`}
        faqData={faqProductsData}
      />
        {productData.textBelowFaq && productData.textBelowFaq.textBelowTheFaqSection && (
            <div className="text-below-faq-section container is-widescreen"
                 dangerouslySetInnerHTML={{
                     __html: productData.textBelowFaq.textBelowTheFaqSection,
                 }}
            />
        )}
      <div className="blue-section">
        <Resources
          title={`${productData.title} Resources`}
          resources={product.productResources}
        />
      </div>
      <RelatedProducts
        agentData={tempAgent}
        relatedProducts={
          tempAgent
            ? tempAgent.agentBusinessProducts.filter(
                (product, key) =>
                  product.products.productName !== productData.title && product
              )
            : state?.availableProducts
            ? state?.availableProducts.filter(
                (item) => item.title !== productData.title
              )
            : product.relatedProducts
        }
      />
      <SpeakWithAgent />
    </Layout>
  );
};

export const query = graphql`
  query productPage($id: String!, $faqCat: String!) {
    productData: wpProduct(id: { eq: $id }) {
      ...ProductContent
      banner {
        bannerUrl
        bannerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
        cancerInsuranceProducts {
            hideWhyThisProductSection
            whyThisProductBoxOneContent
            whyThisProductBoxOneTitle
            whyThisProductBoxThreeContent
            whyThisProductBoxThreeTitle
            whyThisProductBoxTwoContent
            whyThisProductBoxTwoTitle
            whyThisProductSectionTitle
            whyThisProductBoxOneIcon {
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(height: 45, layout: CONSTRAINED)
                    }
                }
            }
            whyThisProductBoxTwoIcon {
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(height: 45, layout: CONSTRAINED)
                    }
                }
            }
            whyThisProductBoxThreeIcon {
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(height: 45, layout: CONSTRAINED)
                    }
                }
            }
        }  
    }
    faqProducts: allWpFaq(
      sort: { fields: date, order: ASC }
      filter: {
        fAQCategories: { nodes: { elemMatch: { slug: { eq: $faqCat } } } }
      }
    ) {
      nodes {
        id
        title
        content
      }
    }
  }
`;

export default ProductPage;
